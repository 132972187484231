.error-container {
  margin: 1em 0;
  padding: 0.25em 2em;
  background-color: var(--mpac-spectral-bg);
  border-radius: 1em;
  border: 2px solid var(--mpac-spectral-border);
  color: var(--mpac-spectral-red);
  width: fit-content;
}

.error-heading {
  font-weight: 600;
}

.error-list {
  list-style: none;
}

.error-list li {
  cursor: pointer;
  color: var(--mpac-spectral-red);
  font-size: 1em;
  padding-bottom: 0.25em;
}

.error-list li:hover {
  text-decoration: underline;
}

.error-list .error-icon {
  margin-right: 0.5em;
}