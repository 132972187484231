/* Accordion header styling */
.accordion {
  width: auto;
}

.accordion-container {
  margin-top: 0.75em;
}

.accordion-header-btn {
  color: var(--mpac-blue) !important;
  background-color: var(--mpac-blue-20);
  font-weight: 600;
  font-size: 0.8em;
  width: 100%;
  border: none;
  /* border: 1px solid transparent; */
  border-bottom: 2px solid var(--mpac-blue) !important;
  border-radius: 5px 5px 0 0;
}

.accordion-header-btn:hover {
  text-decoration: none;
}

.accordion-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.accordion-content {
  padding-top: 1em;
  width: 100%;
}

.accordion-err-count {
  color: darkred;
  font-size: 1.25em;
  margin-left: 1em;
}

/* New Accordion */
.new-accordion-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.new-accordion-title {
  color: black;
}
.new-accordion-hr {
  border-top: 1.5px solid var(--mpac-light-grey-border);
  margin: 0 20px;
  flex-grow: 1;
}
.new-accordion-icon {
  width: 15px;
  font-size: 16px;
  font-weight: bold;
}
.new-accordion-hide {
  display: none;
}