button {
  padding: 0.75em 1em;
}

@media screen and (min-width: 2560px) {
  button {
    padding: 0.7rem 0.875rem;
    font-size: 0.8333rem;
  }
}

button:focus, button:hover {
  outline: none;
  box-shadow: none;
}

.default-button {
  border: none;
  color: white;
  background-color: var(--mpac-main-100);
  border-radius: 5px;
}

.default-button:disabled {
  cursor: not-allowed;
}

.button-submit {
  font-weight: bold;
  color: white;
  background: var(--mpac-main-100);
  border-radius: 5px;
  border: none;
}

.button-cancel {
  font-weight: bold;
  color: var(--mpac-darker-red);
  background: white;
  border-radius: 5px;
  border: 1.4px solid var(--mpac-darker-red);
}

.button-danger {
  font-weight: bold;
  color: white;
  background: var(--mpac-darker-red);
  border-radius: 5px;
  border: none;
}

.button-secondary {
  font-weight: bold;
  color: var(--mpac-main-100);
  background: white;
  border-radius: 5px;
  border: 1.4px solid var(--mpac-main-100);
}

@media screen and (min-width: 2560px) {
  .default-button {
    border-radius: 0.3rem;
  }

  .button-submit {
    border-radius: 0.3rem;
    margin-top: 0.5rem;
  }

  .button-cancel {
    border-radius: 0.3rem;
    border: 0.07rem solid var(--mpac-darker-red);
  }

  .button-danger {
    border-radius: 0.3rem;
  }

  .button-secondary {
    border-radius: 0.3rem;
    border: 0.07rem solid var(--mpac-main-100);
  }
}