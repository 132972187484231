.search-btn {
  background: none;
  border: none;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.search-roll-container input {
  margin: 0;
  border-radius: 5px;
  box-shadow: none;
}

.select-text {
  padding-top: 0.5em 0 0 0;
}

.search-icon {
  font-size: 1.2em;
  padding: 0 0 0 0.75em;
  color: var(--mpac-main-100);
}