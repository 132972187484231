.campaign-list-table {
    width: fit-content;
    padding: 1em;
    text-wrap: nowrap;
}

.campaign-list-table td, th {
    padding: 0.5em 2em;
    text-align: center;
}

.action-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 0.5em;
}

.action-container-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.5em;
}
