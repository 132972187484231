.landing-body-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-height: calc(100vh - 19em - 40px);
}

@media screen and (min-width: 1440px) {
    .landing-body-container {
        min-height: calc(100vh - 21em - 40px);
    }
}

@media screen and (min-width: 2560px) {
    .landing-body-container {
        min-height: calc(100vh - 19em - 40px);
    }
}

.landing-body {
    /* min-width: 60%; */
    width: fit-content;
    max-width: 100vw;
}

.landing-heading {
    margin-bottom: 1em;
    font-size: 1.40em;
    text-align: center;
}

.prop-pagination-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.profile-list-container {
    overflow-x: visible;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.profile-table {
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: stretch;
    text-wrap: nowrap;
}
.profile-table th {
    background: none;
    text-align: left;
    border-bottom: 0.1em solid var(--mpac-main-10);
}

.profile-table tr {
    padding: 0.5rem;
}

.profile-table th,
.profile-table td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.profile-table-body-container tr:hover {
    cursor: pointer;
    background-color: var(--mpac-main-10);
}

/* .roll-width {
    width: 16%;
}

.status-width {
    width: 18%;
}

.type-width {
    width: 22%;
}
*/

.profile-table-body-container td:nth-child(3) {
    width: 25%;
}

/* Status element */
.status {
    display: inline-grid;
    width: 8rem;
    margin: 0 !important;
    border-radius: 0.5em;
    text-transform: uppercase;
    text-align: center;
    border-width: 0.15em;
    border-style: solid;
    text-wrap: nowrap;
    font-weight: bold;
    font-size: smaller;
    padding: 0.25em 0.5em !important;
    border: 0.15em solid var(--mpac-main-100);
    color: var(--mpac-main-100);
    justify-self: end;
}
.status-n {
    border: 0.15em solid var(--mpac-main-100);
    color: var(--mpac-main-100);
}

.status-w {
    border: 0.15em solid #dc582a;
    color: #dc582a;
}

.status-s {
    border: 0.15em solid var(--mpac-green);
    color: var(--mpac-green);
}

.search-roll-container {
    margin-bottom: 0.75em;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
}

.text-right {
    padding: 0.4em;
}