.summary-section-container {
    margin: 1em 1em 1.5em 1em;
}
.summary-section-container td {
    vertical-align: top;
}
.summary-section-container .invisible-header{
    display: none;
}
.summary-container {
    width: 100%;
}
.summary-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width: 1200px) {
    .summary-grid {
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width: 882px) {
    .summary-grid {
        grid-template-columns: 1fr;
    }
}
.summary-page-container .summary-two-grids {
    grid-column: span 2;
}
.summary-page-container .summary-two-grids .partial-width {
    width: 100%;
}
.summary-page-container .bed-counts-table td:not(:first-child):not(:last-child) {
    text-align: left !important;
}
.summary-container .partial-width {
    width: default;
}
.summary-page-container .number-of-sites-table, .summary-page-container .number-of-sites-table td{
    border: none;
}
.summary-page-container .number-of-sites-table .value{
    width: default;
    min-width: none;
    color: var(--mpac-blue);
}
.summary-page-container .number-of-sites-table td:last-child{
    padding-left: 0.7em !important;
}
.summary-page-container .number-of-sites-table tr:hover {
    background-color: white;
}
.summary-page-container .occupancy-statistics-table th {
    padding: 0.5em;
}
.summary-page-container .occupancy-statistics-table td, .summary-page-container .rounds-rate-table td{
    text-align: center;
    padding: 0.5em;
    height: 1.35em;
}
.summary-page-container .rounds-rate-table th {
    padding: 0.5em;
    background-color: var(--mpac-main-10);
    font-weight: bold;
}
.summary-page-container .summary-table {
    width: 100%;
    table-layout:fixed;
    border-collapse: collapse;
    font-size: 1em;
    border: none;
    word-wrap: normal;
}
.summary-page-container .summary-table:not(.summary-table-headerless) tr:first-child td, .summary-page-container .summary-table tr th{
    font-weight: bold;
    padding: 0 0.4em 1.4em 0.3em;
    background: transparent;
    text-align: left;
}
.summary-page-container .summary-table:not(.summary-table-headerless) tr:first-child td:first-child, .summary-page-container .summary-table tr th:first-child{
    color: black;
}
.summary-container .summary-table-headerless tr:first-child td {
    font-weight: normal;
    padding: 0 0.4em 1em 0.3em;
}
.summary-page-container .summary-table:not(.summary-table-headerless) tr:not(:first-child) td:first-child{
    padding-left: 0.5em;
}
.summary-page-container .summary-table td {
    padding: 0 0.4em 1em 0.3em;
}
.summary-page-container .summary-table td:last-child {
    color: var(--mpac-blue);
}
.summary-page-container .summary-table td:last-child, .summary-page-container .summary-table tr th:last-child {
    width: 50%;
}
.summary-page-container .summary-table .summary-currency div{
    display: flex;
    justify-content: space-between;
}
.summary-page-container .summary-table .summary-currency span:last-child, .summary-page-container .summary-right-aligned-cell {
    text-align: right;
}

.submit-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65vh;
}
.print-icon {
    color: #015190;
    font-size: 8em;
}
.print-wip-text {
    font-family: Quicksand, sans-serif;
    font-weight: 800;
    font-size: 1.15em;
    text-align: center;
    padding: 0.5em 0;
    width: 25%;
}
.certify {
    font-family: Quicksand, sans-serif;
    font-weight: 800;
    font-size: 1em;
    text-align: center;
    padding-bottom: 2em;
    width: 50%;
}
#certify-checkbox {
    margin: 0 10px !important;
    font-size: 1.25em;
    color: #333;
    cursor: pointer;
}