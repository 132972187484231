:root {
    --mpac-main-100: #005ca6;
    --mpac-main-60: #6995d5;
    --mpac-main-30: #b4c6e8;
    --mpac-main-20: #cfdaf1;
    --mpac-main-10: #e5ebf8;
    --mpac-grey: #5c5c5c;
    --mpac-medium-grey: #999;
    --mpac-light-grey: #666666;
    --mpac-light-grey-border: #ccc;
    --mpac-lighter-grey: #ebebeb;
    --mpac-yellow: #fdd900;

    --mpac-background: white;
    --mpac-blue: #0055b8;
    --mpac-blue-80: #0055b880;
    --mpac-blue-50: #0055b850;
    --mpac-blue-20: #0055b820;
    --mpac-charcoal: #004261;
    --mpac-charcoal-50: #00426150;
    --mpac-teal: #88D2C2;
    --mpac-teal-50: #88D2C250;
    --mpac-teal-50-100: rgb(196 233 224 /100%);
    --mpac-concrete: #D6DBDE;
    --mpac-concrete-50: #D6DBDE50;
    --mpac-safetyorange: #FF6700;
    --mpac-safetyorange-50: #FF670050;
    --mpac-honeyyellow: #F5AE2B;
    --mpac-honeyyellow-50: #F5AE2B50;
    --mpac-lemonmeringue: #FAF0CA;
    --mpac-lemonmeringue-50: #FAF0CA50;
    --mpac-pastel-yellow: #F4D78D;

    --mpac-hyperlink: #105fa8;
    --mpac-hyperlink-hover: #23527c;
    --mpac-spectral-red: #B91C1C;
    --mpac-spectral-border: #EBD1D1;
    --mpac-spectral-bg: #FEE2E2;
    --mpac-darker-red: #a32719;
    --mpac-green: #32693a;
    --pier-admin-input-text: #AE8E00;
    --pier-admin-input-background: #f5eabd;
    --pier-admin-input-background-disabled: #e0dbc7;
    --pier-admin-background: #f8f0ce;
    --pier-admin-border: #efd896;

    --max-login-container-width: 500px;
    text-rendering: optimizeLegibility;

    --media-small-screen: 550px;
    --media-large-screen: 1200px;

    font-size: 16px;

    --scoll-bar-width: 15px;
}

@media screen and (min-width: 2560px) {
    :root {
        font-size: 32px;
    }
}

:root.medium-text {
    font-size: 1.1rem;
}

:root.large-text {
    font-size: 1.2rem;
}


h1, h2, h3, h4, h5, h6 {
    color: var(--mpac-main-100);
    font-family: Quicksand, sans-serif;
    font-weight: 600;
}

h1 {
    font-size: 1.5em;
}

a {
    text-decoration: none;
    transition: color 200ms, text-shadow 200ms;
}

a:visited {
    color: var(--mpac-main-100);
}

a:is(:hover, :active, :focus) {
    text-shadow: 1px 1px var(--mpac-main-30);
    cursor: pointer;
}

input, select, textarea {
    padding: 6px 12px;
    border: 1px solid var(--mpac-main-30);
    transition: box-shadow 200ms;
    margin: 10px 0px;
}

@media screen and (min-width: 2560px) {
    input, select, textarea {
        border: 2px solid var(--mpac-main-30);
        width: 9rem;
        height: 1.5rem;
        font-size: 0.875rem;
    }
}


input:is(:hover, :active, :focus),
textarea:is(:hover, :active, :focus),
select:is(:hover, :active, :focus) {
    box-shadow: 1px 1px 10px var(--mpac-main-30);
    outline: none;
    cursor: pointer;
}

button:disabled,
input:disabled,
select:disabled,
textarea:disabled {
    cursor: not-allowed;
}

textarea {
    background-color: var(--mpac-main-10);
    border-radius: 5px;
    padding: 0.5em 1em;
    width: 100%;
    resize: none;
    box-sizing: border-box;
}

button {
    border: 1px solid var(--mpac-light-grey-border);
    padding: 5px 10px;
    transition: box-shadow 200ms;
}

button:is(:hover, :active, :focus) {
    box-shadow: 1px 1px 10px var(--mpac-light-grey-border);
    cursor: pointer;
}

label {
    font-weight: bold;
}

hr {
    color: var(--mpac-main-100);
}

ul, li {
    padding: 0;
}

.outline-none {
    outline: none !important;
}

.strong {
    font-weight: 600;
}

.trash, .imp-note {
    color: var(--mpac-darker-red);
}

.trash-off {
    color: var(--mpac-light-grey);
}

.flex {
    display: flex;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-evenly {
    justify-content: space-evenly !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.height-100 {
    height: 100% !important;
}

.width-100 {
    width: 100% !important;
}

.height-fit-content {
    height: -moz-fit-content !important;
    height: fit-content !important;
}

.width-fit-content {
    width: -moz-fit-content !important;
    width: fit-content !important;
}

.padding-left-10 {
    padding-left: 10px !important;
}

.padding-right-10 {
    padding-right: 10px !important;
}

.button-group {
    display: flex;
    gap: 0.5em;
}

.transition-btn-group {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.transition-btn-group>* {
    margin: 0;
}

.noborder-fieldset {
    border: 0;
    padding: 0px;
}

.invisible-legend span {
    display: block;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    height: 0px;
}

.external-link-icon-img {
    height: 0.65rem !important;
}