.checkbox-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 19px;
  height: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  background-color: transparent;
  border: 1px solid var(--mpac-blue-50);
  border-radius: 5px;
}

/* On mouse-over */
.checkbox-container:hover input~.checkmark {
  box-shadow: 1px 1px 10px var(--mpac-blue-30);
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
  background-color: var(--mpac-blue);
}

.checkbox-container:has(input:disabled) {
  background-color: var(--mpac-concrete-50);
  cursor: not-allowed;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  bottom: 4px;
  width: 5px;
  height: 12px;
  border: solid var(--mpac-background);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.admin-input-checkbox {
  background-color: var(--pier-admin-input-background);
  border: 1px solid var(--pier-admin-border);
}

.admin-checkbox-container input:checked~.checkmark {
  background-color: var(--pier-admin-input-background);
}

.admin-checkbox-container .checkmark:after {
  border: solid black;
  left: 5px;
  bottom: 4px;
  width: 5px;
  height: 12px;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}